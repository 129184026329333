
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import {
  required as requiredRule,
  booleanTrueRequired,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { VehicleWithdrawalReason } from '@/types/vehicle-withdrawal-reason'
import { useVehicleWithdrawalReason } from '@/composable/useVehicleWithdrawalReason'

export default defineComponent({
  name: 'VehicleWithdrawalReasons',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingState,
      data: reasons,
      add,
      update,
      remove,
      fetchAll,
    } = useVehicleWithdrawalReason()

    fetchAll()

    function createFormModel(): Partial<VehicleWithdrawalReason> {
      return {
        uuid: uuidv4(),
        reason1: '',
        reason2: '',
        reason3: '',
      }
    }
    const model = ref<Partial<VehicleWithdrawalReason>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'reason1',
        label: 'Orsak 1',
        align: 'left',
        field: 'reason1',
        sortable: true,
      },
      {
        name: 'reason2',
        label: 'Orsak 2',
        align: 'left',
        field: 'reason2',
        sortable: true,
      },
      {
        name: 'reason3',
        label: 'Orsak 3',
        align: 'left',
        field: 'reason3',
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingState.value,
      }
    })

    const exportData = computed(() => {
      return reasons.value.map((reason) => {
        return {
          'Orsak 1': reason.reason1,
          'Orsak 2': reason.reason2,
          'Orsak 3': reason.reason3,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      booleanTrueRequired,
      reasons,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
